// 邀请创建弹窗
// sle
<template>
  <div id="DepartmentEditView">
    <a-modal
      v-model:visible="visible"
      :maskClosable="false"
      title="邀请"
      :width="650"
      :footer="false"
    >
      <a-spin :spinning="spinning">
        <div class="create flex-row" v-if="type === 'create'">
          <!-- 左侧菜单栏 -->
          <div class="left flex-column">
            <a-menu
              mode="inline"
              v-model:selectedKeys="createSelectedKeys"
              @click="menuChange"
            >
              <a-menu-item v-for="item in menu" :key="item.id">
                <a>{{ item.name }}</a>
              </a-menu-item>
            </a-menu>
            <a class="record" @click="watchRecord()">查看邀请记录</a>
          </div>

          <!-- 右侧 -->
          <div class="right">
            <div class="rows flex-row align-center" v-if="link.linkUrl == ''">
              <span style="margin-right: 40px">有效期：</span>
              <a-radio-group
                :options="validitDayList"
                v-model:value="validitValue"
              />
            </div>
            <div v-if="createSelectedKeys[0] === 0" class="link flex-column">
              <div class="rows flex-row align-center">
                <span class="name" v-if="link.linkUrl != ''"> 二维码： </span>
                <div>
                  <div id="qrcode" ref="qrcode"></div>
                </div>
              </div>

              <div class="rows flex-column" v-if="link.linkUrl == ''">
                <div class="placeholder flex-row"></div>
                <a-button
                  type="primary"
                  size="large"
                  block
                  @click="createLinkClick"
                  :loading="link.loading"
                  >生成链接</a-button
                >
              </div>
              <div class="rows flex-column" v-else>
                <div class="flex-row justify-between align-center">
                  <span class="name"> 链接: </span>
                  <a-textarea
                    class="input"
                    v-model:value="link.linkUrl"
                    readonly
                  />
                </div>
                <div class="copy" :data-clipboard-text="link.linkUrl">
                  <a-button type="primary" size="large" block>
                    复制链接
                  </a-button>
                </div>
              </div>
            </div>
            <div
              v-if="createSelectedKeys[0] === 1 || createSelectedKeys[0] === 2"
              class="phoneAndEmail"
            >
              <div
                v-for="(item, index) in addList"
                :key="index"
                class="inputD flex-row align-center"
              >
                <span class="index">
                  {{ index + 1 }}
                </span>
                <a-input
                  v-model:value="addList[index].contactWay"
                  :placeholder="
                    createSelectedKeys[0] === 1 ? '请输入手机' : '请输入邮箱'
                  "
                  class="input"
                />
                <a-button
                  class="button"
                  @click="addListClick(item, index)"
                  v-if="index + 1 === addList.length && index < 9"
                >
                  加行
                </a-button>
              </div>
              <a-button
                type="primary"
                size="large"
                block
                @click="batchCreateLinkClick"
                :loading="link.loading"
                >发送邀请</a-button
              >
            </div>
          </div>
        </div>

        <div class="record flex-column" v-else>
          <div class="flex-row justify-between align-center">
            <a-radio-group
              v-model:value="recordSelectKey"
              @change="recordChange"
            >
              <a-radio-button
                v-for="item in menu"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </a-radio-button>
            </a-radio-group>
            <a class="create" @click="createLink()">邀请</a>
          </div>
          <div v-if="recordSelectKey === 0" class="link">
            <a-list item-layout="vertical" :data-source="recordList">
              <template #renderItem="{ item }">
                <a-list-item>
                  <template #extra>
                    <div class="buttons flex-column align-between">
                      <a-popconfirm
                        cancelText="取消"
                        okText="确认"
                        title="是否确认删除"
                        @confirm="removeLink(item)"
                      >
                        <CloseCircleOutlined class="right-button" />
                      </a-popconfirm>
                      <CopyOutlined
                        class="copy right-button"
                        :data-clipboard-text="item.inviteLink"
                        @click="placeholder"
                      />
                    </div>
                  </template>
                  <a-list-item-meta :description="item.inviteLink">
                    <template #title>
                      {{ item.invitationPsn }}生成了邀请链接
                    </template>
                  </a-list-item-meta>
                  <template #actions> 有效期至{{ item.expireTimeS }} </template>
                </a-list-item>
              </template>
            </a-list>
          </div>
          <div
            v-if="recordSelectKey === 1 || recordSelectKey === 2"
            class="phone"
          >
            <a-list item-layout="vertical" :data-source="recordList">
              <template #renderItem="{ item }">
                <a-list-item>
                  <template #extra>
                    <div class="buttons flex-column align-between">
                      <a @click="sendAgain(item)">再次发送</a>
                    </div>
                  </template>
                  <a-list-item-meta>
                    <template #title>
                      {{ item.contactWay }}
                    </template>
                  </a-list-item-meta>
                </a-list-item>
              </template>
            </a-list>
          </div>
        </div>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import api from '@/api/API'
import { isEmpty } from '@/assets/common.js'
import { CloseCircleOutlined, CopyOutlined } from '@ant-design/icons-vue'
import { Decrypt, Encrypt } from '@/utils/secret.js'
import QRCode from 'qrcodejs2'
import Clipboard from 'clipboard'

export default defineComponent({
  name: 'AAAAAAAA',
  components: {
    CloseCircleOutlined,
    CopyOutlined,
  },
  props: {
    isEmployee: {
      type: Boolean,
    },
  },
  data () {
    return {
      spinning: false, // 是否加载
      visible: false, // 弹窗是否显示
      type: 'create', // 页面功能
      menu: [{ id: 0, name: '链接二维码' }, { id: 1, name: '手机邀请' }, { id: 2, name: '邮箱邀请' }], // 左侧列表
      addList: [],
      validitDayList: [{
        label: '1天',
        value: 1,
      }, {
        label: '3天',
        value: 3,
      }, {
        label: '7天',
        value: 7,
      }], // 有效期列表
      validitValue: 3, // 选择的有效期
      createSelectedKeys: [0], // 当前选择的创建方式
      link: {
        validitValue: 3, // 链接有效期
        linkUrl: '', // 生成的链接
        secretUrl: '', // 加密的链接
        loading: false,
      }, // 创建链接相关

      recordSelectKey: 0, // 查看记录时的选择
      recordList: [], // 数据列表
    }
  },
  mounted () {
    const clipboard = new Clipboard('.copy')
    clipboard.on('success', e => {
      // console.log(e)
      this.$message.success('邀请链接已复制到剪贴板！')
    })
  },
  methods: {
    // 占位方法，无实际功能
    placeholder () { },
    // 打开弹窗
    invitationViewShow () {
      this.visible = true
      this.rebuildAddList()
    },
    // 取消，关闭窗口
    closeDepartmentView () {
      this.visible = false
      this.menuChange()
      this.type = 'create'
    },

    // 生成邀请部分
    createLink () {
      this.type = 'create'
      this.menuChange()
    },
    // 生成二维码
    qrcode () {
      document.getElementById('qrcode').innerHTML = ''
      const qrcode = new QRCode('qrcode', {
        width: 100, // 设置宽度
        height: 100, // 设置高度
        colorDark: '#000000',
        colorLight: '#ffffff',
        text: this.link.linkUrl,
      })
      qrcode._el.title = '' // 鼠标悬浮不展示title
    },
    // 右侧选择部分变更
    menuChange () {
      const link = {
        validitValue: 3,
        linkUrl: '',
        loading: false,
      }
      this.link = link
      this.rebuildAddList()
    },
    // 生成链接
    createLinkClick () {
      this.link.loading = true
      api
        .post('/api/app/employee-invitation/employee-invitation', {
          invitationWay: this.createSelectedKeys[0],
          validDay: this.validitValue,
          isEmployee: this.isEmployee,
        })
        .then(({ data }) => {
          this.$message.success('生成成功')
          this.link.loading = false
          this.link.linkUrl = data
          const splitUrl = this.link.linkUrl.split('?')
          // 先加密链接
          this.link.secretUrl = splitUrl[0] + '/' + Encrypt(splitUrl[1])
          // 然后在这里直接调取发送短信接口
          this.qrcode()
        })
        .catch(err => {
          this.link.loading = false
          // this.$message.error('链接生成失败')
          console.log(err)
        })
    },
    // 批量邀请
    batchCreateLinkClick () {
      const contactWayList = []
      this.addList.forEach(e => {
        if (!isEmpty(e)) {
          contactWayList.push(e.contactWay)
        }
      })
      if (contactWayList.length === 0) {
        return
      }
      this.link.loading = true
      api
        .post('/api/app/employee-invitation/batch-insert-employee-invitation', {
          invitationWay: this.createSelectedKeys[0],
          isEmployee: this.isEmployee,
          validDay: this.validitValue,
          contactWayList: contactWayList,
        })
        .then(({ data }) => {
          this.$message.success(data)
          this.link.loading = false
          this.rebuildAddList()
        })
        .catch(err => {
          this.link.loading = false
          // this.$message.error('链接生成失败')
          console.log(err)
        })
    },

    // 查看记录
    watchRecord () {
      this.type = 'record'
      this.recordChange()
    },
    // 查询记录时变更
    recordChange () {
      this.spinning = true
      api
        .get('/api/app/employee-invitation/employee-invitation-list-by-way', {
          params: {
            invitationWay: this.recordSelectKey,
            isEmployee: this.isEmployee,
          },
        })
        .then(({ data }) => {
          this.spinning = false
          this.recordList = data
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error('获取记录失败')
          console.log(err)
        })
    },
    // 删除链接
    removeLink (e) {
      this.spinning = true
      api
        .delete('/api/app/employee-invitation/employee-invitation', {
          params: {
            Id: e.id,
          },
        })
        .then(({ data }) => {
          this.$message.success('操作成功')
          this.recordChange()
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error('操作失败')
          console.log(err)
        })
    },
    // 再次发送
    sendAgain (e) {
      this.spinning = true
      api
        .post('/api/app/employee-invitation/send-employee-invitation-again', {
          id: e.id,
        })
        .then(({ data }) => {
          this.spinning = false
          this.$message.success('已发送')
          this.recordChange()
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error('发送失败')
          console.log(err)
        })
    },
    // 加行按钮
    addListClick () {
      const obj = {
        contactWay: null,
      }
      this.addList.push(obj)
    },
    // 重置添加框
    rebuildAddList () {
      const list = []
      for (let index = 0; index < 3; index++) {
        const obj = {
          contactWay: null,
        }
        list.push(obj)
      }
      this.addList = list
    },
  },
})
</script>

<style  lang="scss" scoped>
@import "@/assets/common.scss";
.create {
  .right {
    font-size: 18px;
    width: 100%;
    padding-left: 20px;
    .link {
      .rows {
        margin: 10px 10px;
        .placeholder {
          font-size: 18px;
          height: 50px;
          margin-bottom: 10px;
        }
        .name {
          width: 20%;
        }
        .input {
          line-height: 20px;
          width: 80%;
        }
        .copy {
          margin-top: 10px;
        }
      }
    }

    .phoneAndEmail {
      .inputD {
        .index {
          margin-right: 20px;
        }
        .input {
          margin: 10px;
          width: 300px;
        }
      }
    }
  }
  .record {
    margin-top: 20px;
    color: #cbc7c2;
  }
}

.record {
  .buttons {
    margin-right: 10px;
    .right-button {
      margin-bottom: 20px;
      font-size: 25px;
    }
  }
  .create {
    color: #cbc7c2;
  }
  .link {
    height: 480px;
    overflow: auto;
  }
}
</style>
